import React, { Fragment, useEffect } from 'react';
import { Route, useLocation, useNavigate } from 'react-router-dom';
import Account from '../Account/Account';
import SlackAuthenticator from '../Account/SlackAuthenticator';
import Clients from '../Clients/Clients';
import Client from '../Client/Client';
import Company from '../Company/Company';
import EmployeeExperience from '../EmployeeExperience/EmployeeExperience';
import Home from '../Home';
import Intelligence from '../Intelligence/Intelligence';
import Invitation from '../Invitation/Invitation';
import Project from '../Project/Project';
import WorkspaceProjects from '../Workspace/WorkspaceProjects';
import Team from '../Team/Team';
import Teams from '../Team/Teams';
import Workspaces from '../Workspace/Workspaces';
import WorkspaceDashboard from '../Workspace/WorkspaceDashboard';
import ForgotPassword from '../User/ForgotPassword';
import PasswordReset from '../User/PasswordReset';
import SignIn from '../User/SignIn';
import SurveyLogs from '../Admin/SurveyLogs';
import SurveyLogsModuleDrawings from '../Admin/SurveyLogsModuleDrawings';
import TeamClients from '../Team/TeamClients';
import TeamProjects from '../Team/TeamProjects';
import EmployeeExperienceCategoryDashboard from '../EmployeeExperience/EmployeeExperienceCategoryDashboard';
import CompanyCategoryDashboard from '../Company/CompanyCategoryDashboard';
import TeamMembers from '../Team/TeamMembers';
import TeamDashboard from '../Team/TeamDashboard';
import ClientsCategoryDashboard from '../Clients/ClientsCategoryDashboard';
import Workspace from '../Workspace/Workspace';
import ClientsProjects from '../Clients/ClientsProjects';
import Members from '../Account/Members';
import TeamManagement from '../Team/TeamManagement';
import AccountSettings from '../Account/AccountSettings';
import Profile from '../Account/Profile';
import TeamManagementTeam from '../Team/TeamManagementTeam';
import TeamManagementTeams from '../Team/TeamManagementTeams';
import ProjectDashboard from '../Project/ProjectDashboard';
import ProjectAspects from '../Project/ProjectAspects';
import ProjectMembers from '../Project/ProjectMembers';
import ClientProjects from '../Client/ClientProjects';
import ClientAspects from '../Client/ClientAspects';
import ClientMembers from '../Client/ClientMembers';
import ClientDashboard from '../Client/ClientDashboard';
import WorkspaceClients from '../Workspace/WorkspaceClients';
import AspectList from '../Aspect/AspectList';
import AspectDetail from '../Aspect/AspectDetail';
import CompanyProjects from '../Company/CompanyProjects';
import CategoryAspects from '../Category/CategoryAspects';
import ErrorPage, { AppErrorBoundary, NotAuthorizedError, RouteErrorPage } from '../UI/ErrorPage';
import CategoryDashboardOverview from '../Category/CategoryDashboardOverview';
import CategoryDashboardModule from '../Category/CategoryDashboardModule';
import TeamClientList from '../Team/TeamClientList';
import InviteExpired from '../Invitation/InviteExpired';
import CreateWorkspaceRegister from '../Workspace/CreateWorkspaceRegister';
import CreateWorkspaceProfile from '../Workspace/CreateWorkspaceProfile';
import CreateWorkspace from '../Workspace/CreateWorkspace';
import useAuthentication from '../User/useAuthentication';
import useUser from '../User/useUser';
import ClientDashboardOverview from '../Client/ClientDashboardOverview';
import ClientDashboardModule from '../Client/ClientDashboardModule';
import ClientsArea from '../Client/ClientsArea';

export const routes = (rootLayout: React.ReactNode) => (
  <Route path='/' element={rootLayout} errorElement={<AppErrorBoundary />}>
    <Route index element={<Home />} />
    <Route path='get-started' handle={{ title: 'Get Started' }}>
      <Route index element={<CreateWorkspaceRegister />} />
      <Route path='profile' handle={{ title: 'Profile' }} element={<CreateWorkspaceProfile />} />
      <Route
        path='settings'
        handle={{ title: 'Workspace Settings' }}
        element={
          <PrivateRoute>
            <CreateWorkspace />
          </PrivateRoute>
        }
      />
    </Route>
    <Route path='sign-in' handle={{ title: 'Sign In' }} element={<SignIn />} />
    <Route path='invitations' handle={{ title: 'Sign Up' }} element={<Invitation />} />
    <Route path='invite-expired' handle={{ title: 'Invite Expired' }} element={<InviteExpired />} />
    <Route path='forgot-password' handle={{ title: 'Forgot Password' }} element={<ForgotPassword />} />
    <Route path='password-reset' handle={{ title: 'Password Reset' }} element={<PasswordReset />} />
    <Route path='not-authorized' handle={{ title: 'Not Authorized' }} element={<ErrorPage errorType={NotAuthorizedError} includeHeader />} />
    <Route path='account/slack_auth' element={<SlackAuthenticator />} />

    <Route
      path='workspaces'
      handle={{ title: 'Workspaces' }}
      element={
        <PrivateRoute>
          <Workspaces />
        </PrivateRoute>
      }
    />

    <Route
      path='workspace/:workspaceId'
      element={
        <PrivateRoute>
          <Workspace />
        </PrivateRoute>
      }
    >
      <Route index handle={{ title: 'Dashboard', area: 'Workspace' }} element={<WorkspaceDashboard />} />

      <Route path='intelligence' handle={{ title: 'Intelligence', area: 'Intelligence' }} element={<Intelligence />} />

      <Route path='clients' element={<ClientsArea />}>
        <Route path='*' handle={{ title: 'Clients', area: 'Clients' }} element={<Clients />}>
          <Route path='list' handle={{ title: 'Client List', secondaryArea: 'list' }} element={<WorkspaceClients />} />
          <Route path='projects' handle={{ title: 'Client Projects', secondaryArea: 'projects' }} element={<ClientsProjects />} />
          {categoryRoutes('Clients', <ClientsCategoryDashboard />)}
        </Route>

        <Route path=':clientId/*' handle={{ title: 'Client', area: 'Clients' }} element={<Client />}>
          {/*<Route index handle={{ secondaryArea: 'dashboard' }} element={<ClientDashboard />} />*/}
          <Route path='*' handle={{ title: 'Client Dashboard' }} element={<ClientDashboard />}>
            <Route index element={<ClientDashboardOverview />} handle={{ title: 'Client Dashboard', secondaryArea: 'dashboard' }} />
            <Route path='modules/:moduleId' element={<ClientDashboardModule />} handle={{ title: 'Client Assessment', secondaryArea: 'dashboard' }} />
          </Route>
          <Route path='projects' handle={{ title: 'Client Projects', secondaryArea: 'projects' }} element={<ClientProjects />} />
          {componentAspectRoutes(<ClientAspects />)}
          <Route path='members' handle={{ title: 'Client Members', secondaryArea: 'members' }} element={<ClientMembers />} />
        </Route>
      </Route>

      <Route path='projects' handle={{ title: 'Projects', area: 'Projects' }} element={<WorkspaceProjects />} />

      <Route path='projects/:projectId/*' handle={{ title: 'Project', area: 'Projects' }} element={<Project />}>
        <Route index handle={{ title: 'Project Dashboard', secondaryArea: 'dashboard' }} element={<ProjectDashboard />} />
        {componentAspectRoutes(<ProjectAspects />)}
        <Route path='members' handle={{ title: 'Project Members', secondaryArea: 'members' }} element={<ProjectMembers />} />
      </Route>

      <Route path='employee-experience/*' handle={{ title: 'Employee Experience', area: 'Employee Experience' }} element={<EmployeeExperience />}>
        {categoryRoutes('Employee Experience', <EmployeeExperienceCategoryDashboard />)}
      </Route>

      <Route path='company/*' handle={{ title: 'Company', area: 'Company' }} element={<Company />}>
        <Route path='projects' handle={{ title: 'Internal Projects', secondaryArea: 'projects' }} element={<CompanyProjects />} />
        {categoryRoutes('Company', <CompanyCategoryDashboard />)}
      </Route>

      <Route path='teams/*' handle={{ title: 'Teams', area: 'Teams' }} element={<Teams />} />

      <Route path='teams/:teamId/*' handle={{ title: 'Team', area: 'Teams' }} element={<Team />}>
        <Route index handle={{ title: 'Team Dashboard', secondaryArea: 'dashboard' }} element={<TeamDashboard />} />
        <Route path='clients/*' handle={{ title: 'Team Clients', secondaryArea: 'clients' }} element={<TeamClients />}>
          {categoryRoutes('Team Clients', <ClientsCategoryDashboard />, [<Route key='team-client-list' path='list' element={<TeamClientList />} />])}
        </Route>
        <Route path='projects' handle={{ title: 'Team Projects', secondaryArea: 'projects' }} element={<TeamProjects />} />
        <Route path='employee-experience/*' handle={{ title: 'Team Employee Experience', secondaryArea: 'employee-experience' }}>
          {categoryRoutes('Team Employee Experience', <EmployeeExperienceCategoryDashboard />)}
        </Route>
        <Route path='company/*' handle={{ title: 'Team Company Assessments', secondaryArea: 'company' }}>
          {categoryRoutes('Team Company', <CompanyCategoryDashboard />)}
        </Route>
        <Route path='members' handle={{ title: 'Team Members', secondaryArea: 'members' }} element={<TeamMembers />} />
      </Route>

      <Route path='account/*' handle={{ title: 'Account', area: 'Account' }} element={<Account />}>
        <Route path='profile' handle={{ title: 'Account Profile', secondaryArea: 'profile' }} element={<Profile />} />
        <Route path='members' handle={{ title: 'Workspace Members', secondaryArea: 'members' }} element={<Members />} />
        <Route path='teams/*' handle={{ title: 'Team Management', secondaryArea: 'teams' }} element={<TeamManagement />}>
          <Route index element={<TeamManagementTeams />} />
          <Route path=':teamId' element={<TeamManagementTeam />} />
        </Route>
        <Route path='settings' handle={{ title: 'Workspace Settings', secondaryArea: 'settings' }} element={<AccountSettings />} />
      </Route>
    </Route>

    <Route path='/survey_logs/:id/iteration_details/:iteration' handle={{ title: 'Survey Logs', area: 'Account' }} element={<SurveyLogs />} />
    <Route
      path='/survey_logs/:id/module_drawings'
      handle={{ title: 'Survey Logs Assessment Drawings', area: 'Account' }}
      element={<SurveyLogsModuleDrawings />}
    />
    <Route path='*' handle={{ title: 'Error' }} element={<RouteErrorPage />} />
  </Route>
);

const categoryRoutes = (categoryName: string, dashboardLayout: React.ReactNode, additionalRoutes: React.ReactNode[] = []) => (
  <Fragment>
    <Route path='*' element={dashboardLayout}>
      <Route index element={<CategoryDashboardOverview />} handle={{ title: `${categoryName} Dashboard`, secondaryArea: 'dashboard' }} />
      {additionalRoutes}
      <Route path='modules/:moduleId' element={<CategoryDashboardModule />} handle={{ title: 'Assessment Dashboard', secondaryArea: 'dashboard' }} />
    </Route>

    <Route path='modules/:moduleId/aspects/*' handle={{ title: 'Assessment Aspects', secondaryArea: 'aspects' }} element={<CategoryAspects />}>
      <Route index element={<AspectList />} />
      <Route path=':aspectId' handle={{ title: 'Aspect Detail' }} element={<AspectDetail />} />
    </Route>
  </Fragment>
);

const componentAspectRoutes = (layout: React.ReactNode) => (
  <Route path='aspects' handle={{ title: 'Aspects', secondaryArea: 'aspects' }} element={layout}>
    <Route index element={<AspectList />} />
    <Route path=':aspectId' handle={{ title: 'Aspect Detail' }} element={<AspectDetail />} />
  </Route>
);

export const PrivateRoute = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { authenticated } = useAuthentication();
  const { user } = useUser();

  useEffect(() => {
    if (!authenticated) {
      navigate('/sign-in', { replace: true, state: { from: location } });
    }
  }, [authenticated, navigate, location]);

  if (authenticated && user) {
    return <Fragment>{children}</Fragment>;
  } else {
    return null;
  }
};
