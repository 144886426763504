import { useMutation, gql } from '@apollo/client';
import { z } from 'zod';
import { Avatar, Checkbox, FormControlLabel, Icon, Stack, Switch, TextField } from '@mui/material';

import { WORKSPACE_FIELDS_FRAGMENT } from '../Workspace/WorkspaceInterface';
import { useWorkspace } from '../Workspace/useWorkspace';
import CorporateFareRoundedIcon from '@mui/icons-material/CorporateFareRounded';
import FormDialog, { useFormDialog } from '../UI/FormDialog';
import { Controller } from 'react-hook-form';
import { useGlobalMessage } from '../UI/GlobalMessage';
import { UPDATE_CLIENT_MUTATION } from '../Client/ClientInterface';

const editWorkspaceSchema = z.object({
  name: z.string(),
  imageUrl: z.string().url().optional(),
  domain: z.string().optional(),
  aiEnabled: z.boolean().optional(),
});

type EditWorkspaceValues = z.infer<typeof editWorkspaceSchema>;

interface EditWorkspaceDialogProps {
  open: boolean;
  onClose: () => void;
  refetch: () => Promise<any>;
}

const EditWorkspaceDialog = ({ open, onClose, refetch }: EditWorkspaceDialogProps) => {
  const { workspace } = useWorkspace();
  const { setSuccess } = useGlobalMessage();

  const [updateWorkspace, { error: updateWorkspaceError }] = useMutation(UPDATE_WORKSPACE_MUTATION);
  const [updateClient, { error: updateClientError }] = useMutation(UPDATE_CLIENT_MUTATION);

  const defaultValues = {
    name: workspace.name,
    company: {
      imageUrl: workspace.company.imageUrl || undefined,
      domain: workspace.company.domain || undefined,
    },
    aiEnabled: workspace.aiEnabled || false,
  };
  const formDialogProps = useFormDialog<EditWorkspaceValues>({ schema: editWorkspaceSchema, defaultValues });
  const { methods, handleResponse } = formDialogProps;
  const { control, watch } = methods;
  const imageUrl = watch('imageUrl');

  const handleSubmit = async (values: EditWorkspaceValues) => {
    const updateClientResponse = await updateClient({
      variables: {
        id: workspace.company.id,
        name: values.name,
        imageUrl: values.imageUrl || null,
        domain: values.domain || null,
      },
    });
    const updateClientSuccess = handleResponse(updateClientResponse, 'updateClient', 'Uh oh. There was a problem updating the workspace');

    const updateWorkspaceResponse = await updateWorkspace({
      variables: {
        id: workspace.id,
        name: values.name,
        aiEnabled: values.aiEnabled,
      },
    });
    const updateWorkspaceSucess = handleResponse(updateWorkspaceResponse, 'updateWorkspace', 'Uh oh. There was a problem updating the workspace');
    await refetch();

    if (updateClientSuccess && updateWorkspaceSucess) {
      setSuccess('Updated workspace');
    }

    return updateClientSuccess && updateWorkspaceSucess;
  };

  return (
    <FormDialog
      {...formDialogProps}
      id="edit-workspace"
      open={open}
      onClose={onClose}
      icon={<Icon>lock</Icon>}
      title="Manage Workspace"
      subtitle="Edit the information below to update your workspace."
      submitLabel="Save"
      onSubmit={handleSubmit}
      mutationError={updateWorkspaceError || updateClientError}
    >
      <Stack spacing={2}>
        <Controller
          name="name"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField {...field} variant="outlined" fullWidth label="Workspace Name" error={!!error}
                       helperText={error?.message} />
          )}
        />

        <Stack direction="row" spacing={2} alignItems="center">
          <Controller
            name="imageUrl"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField {...field} variant="outlined" fullWidth label="Workspace Image URL" error={!!error}
                         helperText={error?.message} />
            )}
          />

          <Avatar src={imageUrl}>
            <CorporateFareRoundedIcon />
          </Avatar>
        </Stack>

        <Stack direction="row" ml={-2}>
          <Controller
            name="aiEnabled"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} checked={field.value} />}
                label="AI Enabled"
              />
            )}
          />
        </Stack>
      </Stack>
    </FormDialog>
  );
};

export default EditWorkspaceDialog;

const UPDATE_WORKSPACE_MUTATION = gql`
  mutation UpdateWorkspace($id: ID!, $name: String!, $aiEnabled: Boolean) {
    updateWorkspace(id: $id, name: $name, aiEnabled: $aiEnabled) {
      workspace {
        ...WorkspaceFields
      }
    }
  }
  ${WORKSPACE_FIELDS_FRAGMENT}
`;
