import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthentication from './User/useAuthentication';
import useWorkspaceSelection from './Workspace/useWorkspaceSelection';
import ErrorPage from './UI/ErrorPage';
import PageStyle from './UI/PageStyle';

const Home = () => {
  const navigate = useNavigate();
  const { authenticated } = useAuthentication();
  const { workspaceId, loading } = useWorkspaceSelection();

  useEffect(() => {
    if (!authenticated) {
      navigate('/sign-in', { replace: true });
    }
  }, [authenticated, navigate]);

  useEffect(() => {
    if (workspaceId) {
      navigate(`/workspace/${workspaceId}`, { replace: true });
    }
  }, [workspaceId, navigate]);

  if (loading) {
    return <PageStyle includeHeader={true} loading={true} />
  }

  if (workspaceId === null) {    
    return <ErrorPage
      message={"Looks like you’re not part of any workspaces yet! You can either create a new one or ask someone to invite you."}
      action={{ label: 'Create New Workspace', path: '/get-started/settings' }}
    />;
  }

  return null;
};

export default Home;


