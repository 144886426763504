import React, { createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UserInterface } from '../User/User';
import { WORKSPACE_ID_KEY } from './WorkspaceInterface';
import useAuthenticatedUser from '../User/useAuthenticatedUser';

interface WorkspaceSelectionContextType {
  workspaceId: string | null;
  setWorkspaceId: (id: string) => void;
  loading: boolean;
}

const WorkspaceSelectionContext = createContext<WorkspaceSelectionContextType | undefined>(undefined);

const useWorkspaceSelection = () => {
  const context = useContext(WorkspaceSelectionContext);
  if (!context) {
    throw new Error('useWorkspaceSelection must be used within a WorkspaceSelectionProvider');
  }
  return context;
};

export default useWorkspaceSelection;

export const WorkspaceSelectionProvider = ({ children }: { children: React.ReactNode }) => {
  const { workspaceId: paramsWorkspaceId } = useParams();

  const { user } = useAuthenticatedUser();
  const [workspaceId, setWorkspaceId] = useState<string | null>(determineWorkspaceId(paramsWorkspaceId, user));
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user) {
      setWorkspaceId(determineWorkspaceId(paramsWorkspaceId, user));
      setLoading(false);
    }
  }, [paramsWorkspaceId, user]);

  const value = {
    workspaceId,
    setWorkspaceId,
    loading,
  };

  return <WorkspaceSelectionContext.Provider value={value}>{children}</WorkspaceSelectionContext.Provider>;
};

const determineWorkspaceId = (paramsWorkspaceId: string | undefined, user: UserInterface): string | null => {
  const localStorageWorkspaceId = getWorkspaceIdFromLocalStorage(user);

  let workspaceId = null;
  if (paramsWorkspaceId) {
    workspaceId = paramsWorkspaceId;
  } else if (localStorageWorkspaceId) {
    workspaceId = localStorageWorkspaceId;
  } else if (user) {
    if (user.workspaces.length) {
      workspaceId = user.workspaces[0].id;
    }
  }

  if (workspaceId) {
    localStorage.setItem(WORKSPACE_ID_KEY, workspaceId);
  } else {
    localStorage.removeItem(WORKSPACE_ID_KEY);
  }
  return workspaceId;
};

const getWorkspaceIdFromLocalStorage = (user: UserInterface | null): string | null => {
  if (!user) {
    return null;
  }

  const localStorageWorkspaceId = localStorage.getItem(WORKSPACE_ID_KEY);
  if (localStorageWorkspaceId) {
    if (user.workspaces.some(workspace => workspace.id === localStorageWorkspaceId)) {
      return localStorageWorkspaceId;
    } else {
      localStorage.removeItem(WORKSPACE_ID_KEY);
      return null;
    }
  } else {
    return null;
  }
};
