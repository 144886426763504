import React, { useContext, useEffect } from 'react';
import { styled, ThemeProvider } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { Divider, IconButton, Stack, Typography } from '@mui/material';
import { useLayout } from './LayoutContext';
import NoticeContext from '../UI/NoticeContext';
import { IconType } from './Icon';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { ActionInterface } from './ActionInterface';
import { LIGHT_THEME } from '../Theme/LightTheme';
import { DARK_THEME } from '../Theme/DarkTheme';

interface PageNoticeProps {
  noticeKey: string;
  applicable: boolean;
  level?: 'medium' | 'high';
  icon?: IconType;
  message: string;
  actions?: ActionInterface[];
  closable?: boolean;
  rounded?: boolean;
}

const PageNotice = ({ noticeKey, applicable, level = 'medium', icon, message, actions = [], closable = true, rounded = false }: PageNoticeProps) => {
  if (!noticeKey) {
    throw new Error('noticeKey is required');
  }
  const { registerNotice, noticeOpen, closeNotice } = useContext(NoticeContext);
  const layout = useLayout();

  useEffect(() => {
    registerNotice(noticeKey);
  }, [registerNotice, noticeKey]);

  const handleClose = () => {
    closeNotice(noticeKey);
  };

  //  console.log(noticeOpen(noticeKey));
  //  console.log(applicable);

  if (!noticeOpen(noticeKey)) {
    return null;
  }

  if (!applicable) {
    return null;
  }

  const noticeTheme = level === 'high' ? LIGHT_THEME : DARK_THEME;

  return (
    <ThemeProvider theme={noticeTheme}>
      <StyledPageNotice
        className={`notice-level-${level}`}
        position='relative'
        direction='row'
        alignItems='center'
        sx={{ borderRadius: rounded ? '4px' : undefined }}
      >
        <Stack direction={layout === 'mobile' ? 'column' : 'row'} alignItems='center' flexGrow={1} px={2} py={1} spacing={1}>
          {icon && (
            <Stack alignItems='center' pr={1}>
              {React.createElement(icon)}
            </Stack>
          )}

          <Stack
            className='page-notice-message'
            flexGrow={1}
            // alignItems='center'
          >
            <Typography variant='body1' color='primary'>
              {message}
            </Typography>
          </Stack>

          <Stack
            flexGrow={0}
            className='page-notice-actions'
            direction={layout === 'mobile' ? 'column' : 'row'}
            alignItems='center'
            spacing={1}
            sx={{ whiteSpace: 'nowrap' }}
          >
            {actions.map((action, index) => (
              <LoadingButton
                variant='outlined'
                key={index}
                onClick={() => action.onSelect?.()}
                startIcon={action.icon ? React.createElement(action.icon) : undefined}
                disabled={action.disabled}
                loading={action.loading}
                loadingPosition='start'
              >
                {action.label}
              </LoadingButton>
            ))}
          </Stack>

          {closable && (
            <div className='page-notice-dismiss'>
              <IconButton edge='end' onClick={handleClose}>
                <CloseRoundedIcon />
              </IconButton>
            </div>
          )}
        </Stack>
      </StyledPageNotice>
    </ThemeProvider>
  );
};

export const PageNotices = ({ children }: { children: React.ReactNode }) => {
  return <Stack divider={<Divider flexItem sx={{ backgroundColor: '#1a1a1a' }} />}>{children}</Stack>;
};

const StyledPageNotice = styled(Stack)`
  // min-height: 50px;
  &.notice-level-high {
    background-color: #ffd73e;
  }

  &.notice-level-medium {
    background-color: #333;
    color: #aaa;
    .MuiButton-root {
      color: inherit;
      border-color: #aaa;
    }
    .MuiIconButton-root {
      color: inherit;
    }
  }

  .mobile & {
    .page-notice-message {
      padding: 0px 40px;
      text-align: center;
    }
    .page-notice-dismiss {
      position: absolute;
      top: 0px;
      right: 16px;
    }
  }
`;

export default PageNotice;
