import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { Divider, Drawer, IconButton, Modal, Stack, Typography } from '@mui/material';

import MenuOpenRoundedIcon from '@mui/icons-material/MenuOpenRounded';

import { PrimaryNavigationArea } from './usePrimaryNavigation';
import { useWorkspace } from '../Workspace/useWorkspace';
import { NAVIGATION_THEME } from '../UI/NavigationTheme';
import { DrawerContent } from './PrimaryDrawerNavigation';
import { NavigationViewport } from './PrimaryNavigation';
import PrimaryBottomNavigation from './PrimaryBottomNavigation';
import AIPanel from '../AI/AIPanel';
import { useAI } from '../AI/AIContext';
import { useDrawer } from './DrawerContext';

interface PrimaryMobileNavigationProps {
  areas: { [key: string]: PrimaryNavigationArea };
  children: React.ReactNode;
}

const PrimaryMobileNavigation = ({ areas, children }: PrimaryMobileNavigationProps) => {
  const { open, setOpen } = useDrawer();
  const { workspace } = useWorkspace();
  const { open: aiOpen, setOpen: setAIOpen } = useAI();
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  //  console.log(viewportHeight);

  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    if (window.visualViewport) {
      window.visualViewport.addEventListener('resize', handleResize);
    }

    return () => {
      window.removeEventListener('resize', handleResize);
      if (window.visualViewport) {
        window.visualViewport.removeEventListener('resize', handleResize);
      }
    };
  }, []);

  if (!workspace) {
    return null;
  }

  const drawerAreas = ['clients', 'projects', 'employee-experience', 'company', 'teams', 'account'].map(key => areas[key]).filter(area => area);

  return (
    <>
      <Stack className='bottom-navigation-layout' height='100%'>
        <ThemeProvider theme={NAVIGATION_THEME}>
          <Drawer anchor='left' open={open} onClose={() => setOpen(false)}>
            <Stack width='85vw'>
              <Stack direction='row' height='64px' px={2.5} justifyContent='center'>
                <Stack flex={1} justifyContent='center'>
                  <Typography>{workspace.name}</Typography>
                </Stack>
                <IconButton edge='end' onClick={() => setOpen(false)}>
                  <MenuOpenRoundedIcon />
                </IconButton>
              </Stack>
              <Divider />
              <DrawerContent open={true} areas={drawerAreas} onSelect={() => setOpen(false)} />
            </Stack>
          </Drawer>
        </ThemeProvider>

        <NavigationViewport>{children}</NavigationViewport>

        <Modal open={aiOpen} onClose={() => setAIOpen(false)}>
          <Stack
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              // transform: 'translate(-50%, -50%)',
              width: '100%', // Adjust width as needed
              maxHeight: `${viewportHeight}px`,
              height: '100%',
              bgcolor: 'background.paper',
              paddingBottom: '63px',
            }}
          >
            <AIPanel />
          </Stack>
        </Modal>

        <PrimaryBottomNavigation />
      </Stack>
    </>
  );
};

export default PrimaryMobileNavigation;
