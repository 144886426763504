import { Avatar, Icon, IconButton, Stack, Tooltip, Typography } from '@mui/material';

import useAuthentication from '../User/useAuthentication';
import SecondaryNavigation, { SecondaryNavigationActions, SecondaryNavigationHeading } from '../Navigation/SecondaryNavigation';
import { SecondaryNavigationTabs, useSecondaryNavigationTabs } from '../Navigation/SecondaryNavigationTabs';
import { Role } from '../User/Roles';
import useWorkspace from '../Workspace/useWorkspace';
import { useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import SignOutDialog from './SignOutDialog';

type AccountTab = {
  key: string;
  label: string;
  path: string;
  roles?: Role[];
};

const TABS: AccountTab[] = [
  { key: 'profile', label: 'Profile', path: 'profile' },
  { key: 'members', label: 'Members', path: 'members' },
  { key: 'teams', label: 'Teams', path: 'teams', roles: ['Admin', 'SystemAdmin'] },
  { key: 'settings', label: 'Settings', path: 'settings', roles: ['Admin', 'SystemAdmin'] }
];

const useSignOut = () => {
  const client = useApolloClient();
  const { deauthenticate } = useAuthentication();
  const navigate = useNavigate();

  return async () => {
    deauthenticate();
    await client.clearStore();
    navigate('/sign-in');
  };
};

const AccountSecondaryNavigation = () => {
  const { workspacePath } = useWorkspace();
  const secondaryNavigationTabsProps = useSecondaryNavigationTabs(TABS, `${workspacePath}/account`);
  const [signOutDialogOpen, setSignOutDialogOpen] = useState(false);

  const signOut = useSignOut();

  return (
    <SecondaryNavigation>
      <SecondaryNavigationHeading>
        <Avatar>
          <Icon>person</Icon>
        </Avatar>
        <Typography variant='h6' className='title'>
          Account
        </Typography>
      </SecondaryNavigationHeading>
      <SecondaryNavigationActions>
        <SecondaryNavigationTabs {...secondaryNavigationTabsProps} />
        <Stack direction='row'>
          <Tooltip title='Sign Out'>
            <IconButton onClick={() => setSignOutDialogOpen(true)}>
              <Icon>exit_to_app</Icon>
            </IconButton>
          </Tooltip>
        </Stack>
        <SignOutDialog open={signOutDialogOpen} onClose={() => setSignOutDialogOpen(false)} onSubmit={signOut} />
      </SecondaryNavigationActions>
    </SecondaryNavigation>
  );
};

export default AccountSecondaryNavigation;

