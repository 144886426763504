import { useWorkspace } from '../Workspace/useWorkspace';
import { useCollectIntelligence } from '../Intelligence/ContributeAndCollect';
import React, { Fragment } from 'react';
import { Link, Typography } from '@mui/material';

export const CollectIntelligence = () => {
  const { workspace } = useWorkspace();
  const { collectIntelligence, collectIntelligenceDialog } = useCollectIntelligence(workspace);

  return (
    <Fragment>
      <div className='collect-intelligence'>
        <Typography variant='body2'>Want to collect intelligence from the entire team?</Typography>
        <Link component='button' onClick={collectIntelligence}>
          Click here!
        </Link>
      </div>
      {collectIntelligenceDialog}
    </Fragment>
  );
};
