import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useQuery, useMutation, gql } from '@apollo/client';

import { Stack, Typography } from '@mui/material';

import PageStyle from '../UI/PageStyle';
import useAuthentication from '../User/useAuthentication';
import SignUpFormContent, { useSignUpForm, Schema } from '../User/SignUpFormContent';
import BrandedForm from '../UI/BrandedForm';

const Invitation = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token') || undefined;

  const { signIn, authenticate, authenticated, deauthenticate } = useAuthentication();
  const [errorMessage] = useState();
  const [acceptInvite] = useMutation(ACCEPT_INVITE_MUTATION);

  const { initialValues, schema } = useSignUpForm();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: initialValues,
  });

  useEffect(() => {
    if (authenticated) {
      navigate('/');
    }
  }, [authenticated, navigate]);

  const response = useQuery(INVITATION_QUERY, { variables: { token: token } });
  //  console.log(response);

  const { data, loading } = response;
  if (!data || loading) {
    return <PageStyle design='standard' loading={true} />;
  }

  const { invite } = data;

  if (!invite.valid) {
    navigate('/invite-expired');
  }

  const workspace = invite.workspaces[0];

  const onSubmit = async ({ firstName, lastName, password, timeZone }: Schema) => {
    deauthenticate(); // or try to sign up without sending the signed in user's authorization header
    const response = await acceptInvite({
      variables: {
        token: token,
        firstName: firstName,
        lastName: lastName,
        password: password,
        passwordConfirmation: password,
        workspaceIds: [workspace.id],
        timeZone: timeZone,
      },
    });

    if (response.data?.acceptInvite.success) {
      await signIn(invite.user.email, password);
      authenticate();
    }
  };

  return (
    <PageStyle design='branded'>
      <BrandedForm errorMessage={errorMessage} onSubmit={handleSubmit(onSubmit)} title={`Join the Hyve workspace for ${workspace.name}`}>
        <Stack spacing={4}>
          <SignUpFormContent control={control} isSubmitting={isSubmitting} email={invite.user.email} submitButtonText='Create Account' />

          <Stack>
            <Typography variant='body1' textAlign='center'>
              Already have an account?{' '}
              <Link to={'/sign-in'} className={'tertiary'}>
                Sign In
              </Link>
            </Typography>
            <Typography variant='body2' textAlign='center'>
              By continuing, you're agreeing to our
              <br />
              <Link to={`${process.env.REACT_APP_MARKETING_SITE}/terms-of-service`}>Terms of Service</Link>&nbsp;and&nbsp;
              <Link to={`${process.env.REACT_APP_MARKETING_SITE}/privacy`}>Privacy Policy</Link>
            </Typography>
          </Stack>
        </Stack>
      </BrandedForm>
    </PageStyle>
  );
};

export default Invitation;

const INVITATION_QUERY = gql`
  query Invite($token: ID) {
    invite(token: $token) {
      user {
        id
        email
      }
      valid
      workspaces {
        id
        name
        accepted
      }
    }
  }
`;
const ACCEPT_INVITE_MUTATION = gql`
  mutation AcceptInvite($token: ID!, $firstName: String!, $lastName: String!, $password: String!, $passwordConfirmation: String!, $timeZone: String!) {
    acceptInvite(
      token: $token
      firstName: $firstName
      lastName: $lastName
      password: $password
      passwordConfirmation: $passwordConfirmation
      timeZone: $timeZone
    ) {
      success
      errors {
        type
        fullMessage
      }
    }
  }
`;
