import { useState, useEffect } from 'react';
import { gql, useMutation } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Alert } from '@mui/material';

import SignUpFormContent, { useSignUpForm, Schema } from '../User/SignUpFormContent';
import CreateWorkspaceSteps from './CreateWorkspaceSteps';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useAuthentication from '../User/useAuthentication';
import { USER_FIELDS } from '../User/User';
import { ApiResponse, UNKNOWN_ERROR_MESSAGE } from '../util/hyveApi';

interface ConfirmRegistrationResponse {
  confirmRegistration: ApiResponse;
}

const CreateWorkspaceProfile = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const { schema, initialValues } = useSignUpForm();
  const [errorMessage, setErrorMessage] = useState('');
  const [confirmRegistration] = useMutation<ConfirmRegistrationResponse>(CONFIRM_REGISTRATION_MUTATION);
  const navigate = useNavigate();
  const { deauthenticate, authenticate } = useAuthentication();

  useEffect(() => {
    deauthenticate();
  }, [deauthenticate]);

  interface FormValues {
    firstName: string;
    lastName: string;
    password: string;
  }

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: initialValues,
  });

  const onSubmit = async ({ firstName, lastName, password }: FormValues) => {
    const response = await confirmRegistration({ variables: { token, firstName, lastName, password } });
    const { data } = response;

    if (data?.confirmRegistration.success) {
      authenticate();
      navigate('../settings');
    } else if (data?.confirmRegistration.errors) {
      console.error(data);
      setErrorMessage(data.confirmRegistration.errors[0].fullMessage);
    } else {
      setErrorMessage(UNKNOWN_ERROR_MESSAGE);
    }
  };

  return (
    <CreateWorkspaceSteps step={1}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {errorMessage && (
          <div className='form-row'>
            <Alert severity='error'>{errorMessage}</Alert>
          </div>
        )}
        <SignUpFormContent control={control} isSubmitting={isSubmitting} submitButtonText='Continue' />
      </form>
    </CreateWorkspaceSteps>
  );
};

export default CreateWorkspaceProfile;

const CONFIRM_REGISTRATION_MUTATION = gql`
  mutation ConfirmRegistration($token: String!, $firstName: String!, $lastName: String!, $password: String!) {
    confirmRegistration(token: $token, firstName: $firstName, lastName: $lastName, password: $password) {
      success
      errors {
        type        
        fullMessage
      }
      user {
        ${USER_FIELDS}
      }
    }
  }
`;
