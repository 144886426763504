import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { z } from 'zod';
import { Stack } from '@mui/material';

import { useGlobalMessage } from '../UI/GlobalMessage';
import FormDialog, { useFormDialog } from '../UI/FormDialog';
import ChannelSelect from './ChannelSelect';
import { EditRounded } from '@mui/icons-material';
import { ChannelConnection } from './ChannelConnectable';

const editChannelSchema = z.object({
  channelName: z.string().nullable(),
});

type EditChannelValues = z.infer<typeof editChannelSchema>;

interface ChannelConnectionDialogProps {
  channelConnection: ChannelConnection;
  open: boolean;
  onClose: () => void;
}

const EditChannelDialog = ({ channelConnection, open, onClose }: ChannelConnectionDialogProps) => {
  const [updateChannelConnectionMutation, { error }] = useMutation(UPDATE_CHANNEL_CONNECTION_MUTATION);
  const { setSuccess } = useGlobalMessage();
  const { channel } = channelConnection;

  const formDialogProps = useFormDialog<EditChannelValues>({ schema: editChannelSchema, defaultValues: { channelName: channel.name } });
  const { handleResponse } = formDialogProps;

  const handleSubmit = async (values: EditChannelValues) => {
    const response = await updateChannelConnectionMutation({
      variables: {
        id: channelConnection.id,
        channelName: values.channelName,
      },
    });

    const success = handleResponse(response, 'updateChannelConnection', 'Uh oh. There was a problem updating the channel');
    if (success) {
      setSuccess('Updated channel settings');
    }
    return success;
  };

  return (
    <FormDialog<EditChannelValues>
      {...formDialogProps}
      id='channel-connection'
      open={open}
      onClose={onClose}
      icon={<EditRounded />}
      title='Edit Channel Settings'
      subtitle={`Enter the information below to update the channel connection.`}
      onSubmit={handleSubmit}
      submitLabel='Connect'
      mutationError={error}
    >
      <Stack spacing={2}>{/*<ChannelSelect channel={channel} />*/}</Stack>
    </FormDialog>
  );
};

export default EditChannelDialog;

const UPDATE_CHANNEL_CONNECTION_MUTATION = gql`
  mutation UpdateChannelConnection($id: ID!, $channelName: String!) {
    updateChannelConnection(id: $id, channelName: $channelName) {
      channelConnectable {
        id
        name
        channelConnections {
          id
          channelName
        }
      }
      success
      errors {
        type
        fullMessage
      }
    }
  }
`;
