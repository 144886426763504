import { styled } from '@mui/material/styles';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ActionInterface } from './ActionInterface';
import { IconType } from './Icon';
import React from 'react';

interface ListNoticeProps {
  icon: IconType;
  title: string;
  message: string | React.ReactNode;
  actions: ActionInterface[];
}

const ListNotice = ({ icon, title, message, actions }: ListNoticeProps) => {
  return (
    <StyledListNotice flexGrow={1} spacing={2} alignItems='center' justifyContent='center'>
      <Stack alignItems='center'>
        <div className='notice-icon'>{React.createElement(icon)}</div>
        <Typography variant='h2' className='notice-title'>
          {title}
        </Typography>
      </Stack>

      <Typography variant='body2' textAlign='center' className='notice-message'>
        {message}
      </Typography>

      <Stack className='notice-actions' direction='row' justifyContent='center' spacing={1}>
        {actions.map((action, index) => (
          <Button className='notice-action' key={index} variant='contained' disableElevation color='secondary' onClick={action.onSelect}>
            {action.label}
          </Button>
        ))}
      </Stack>
    </StyledListNotice>
  );
};

const StyledListNotice = styled(Stack)`
  .notice-icon {
    .MuiIcon-root {
      font-size: 48px;
      margin-bottom: 0;
    }
    svg {
      width: 48px;
      height: 48px;
    }
  }
`;

export default ListNotice;
