import React, { Fragment } from 'react';
import Stack from '@mui/material/Stack';
import { useLayout } from '../UI/LayoutContext';
import { usePrimaryNavigation, PrimaryNavigationAreas } from './usePrimaryNavigation';
import PrimaryDrawerNavigation from '../Navigation/PrimaryDrawerNavigation';
import PrimaryTopNavigation from '../Navigation/PrimaryTopNavigation';
import PrimaryMobileNavigation from './PrimaryMobileNavigation';
import AIDrawer from '../AI/AIDrawer';
import { DrawerProvider } from './DrawerContext';

const PrimaryNavigation = ({ children }: { children: React.ReactNode }) => {
  const layout = useLayout();
  const { areas, activeArea } = usePrimaryNavigation();

  if (!activeArea) {
    return <Fragment>{children}</Fragment>;
  }

  if (activeArea) {
    activeArea.active = true;
  }

  return (
    <DrawerProvider>
      <Stack height='100vh'>
        {layout !== 'mobile' ? (
          <TopNavigation areas={areas}>{children}</TopNavigation>
        ) : (
          <PrimaryMobileNavigation areas={areas}>{children}</PrimaryMobileNavigation>
        )}
      </Stack>
    </DrawerProvider>
  );
};

const TopNavigation = ({ areas, children }: { areas: PrimaryNavigationAreas; children: React.ReactNode }) => {
  return (
    <Stack className='top-navigation-layout' height='100%'>
      <PrimaryTopNavigation />
      <NavigationViewport>
        <PrimaryDrawerNavigation areas={areas}>{children}</PrimaryDrawerNavigation>
        <AIDrawer />
      </NavigationViewport>
    </Stack>
  );
};

export const NavigationViewport = ({ children }: { children: React.ReactNode }) => (
  <Stack
    className='navigation-viewport'
    sx={{
      flexGrow: 1,
      overflowY: 'scroll',
      position: 'relative',
    }}
  >
    {children}
  </Stack>
);

export default PrimaryNavigation;
