import React from 'react';
import useWorkspace from '../Workspace/useWorkspace';
import hyveApi from '../util/hyveApi';

export type SlackAuthType = 'app' | 'user';

export type SlackUpdateStatus = 'revoking' | 'error' | 'revoked';

const SLACK_SCOPES = {
  app: [
    'bookmarks:read',
    'bookmarks:write',
    'channels:join',
    'channels:manage',
    'channels:read',
    'chat:write',
    'chat:write.public',
    'dnd:read',
    'groups:read',
    'groups:write',
    'im:history',
    'im:read',
    'im:write',
    'reactions:read',
    'reactions:write',
    'team:read',
    'users:read',
    'users:read.email',
  ],
  user: ['bookmarks:read', 'bookmarks:write', 'channels:read', 'channels:write', 'groups:history', 'groups:read', 'groups:write', 'im:read', 'mpim:read'],
};

const generateSlackUrl = (authType: SlackAuthType, state: string, slackRedirectUri: string) => {
  const baseUrl = 'https://slack.com/oauth/v2/authorize';
  const clientId = process.env.REACT_APP_SLACK_CLIENT_ID;
  const scopes = SLACK_SCOPES[authType].join(',');
  const userScopeValue = authType === 'user' ? scopes : '';
  const scopeValue = authType === 'app' ? scopes : '';

  return `${baseUrl}?scope=${scopeValue}&user_scope=${userScopeValue}&client_id=${clientId}&state=${encodeURIComponent(
    state,
  )}&redirect_uri=${encodeURIComponent(slackRedirectUri)}`;
};

export const useAuthorizeSlack = () => {
  const { workspace } = useWorkspace();
  const postAuthRedirect = window.location.pathname;
  const slackRedirectUri = `${process.env.REACT_APP_SLACK_AUTH_REDIRECT_ORIGIN || window.location.origin}/account/slack_auth`;

  const generateAuthorizeURL = (authType: SlackAuthType) => {
    const state = JSON.stringify({
      workspaceId: workspace.id,
      postAuthRedirect,
      authType,
    });

    return generateSlackUrl(authType, state, slackRedirectUri);
  };

  return {
    authorizeAppScopesURL: generateAuthorizeURL('app'),
    authorizeUserScopesURL: generateAuthorizeURL('user'),
  };
};

export const useRevokeSlackToken = (
  authType: SlackAuthType,
  workspaceId: string,
  refetch: () => Promise<void>,
  setSlackUpdateStatus: React.Dispatch<React.SetStateAction<SlackUpdateStatus | null>>,
) => {
  return async () => {
    try {
      setSlackUpdateStatus('revoking');
      const revokeUrl = authType === 'app' ? '/slack/revoke_app' : '/slack/revoke_user';

      await hyveApi.create().post(revokeUrl, { workspace_id: workspaceId });
      await refetch();
      setSlackUpdateStatus('revoked');
    } catch (error) {
      console.error(`Error when revoking: ${error}`);
      setSlackUpdateStatus('error');
    }
  };
};
