import { Autocomplete, TextField, InputAdornment, ListItem, ListItemIcon, ListItemText, Link, Typography, Button } from '@mui/material';
import LockIconRounded from '@mui/icons-material/LockRounded';
import TagIconRounded from '@mui/icons-material/TagRounded';
import { Controller, useFormContext } from 'react-hook-form';
import useWorkspace from '../Workspace/useWorkspace';
import { Channel } from './Channel';
import { useAuthorizeSlack } from '../Account/useSlackIntegration';

type ChannelSelectProps = {
  options: Channel[];
};

const ChannelSelect = ({ options }: ChannelSelectProps) => {
  const { workspace } = useWorkspace();
  const { control } = useFormContext();
  const { authorizeUserScopesURL } = useAuthorizeSlack();

  return (
    <>
      {!workspace.user.hasSlackUserAccess && (
        <Typography>
          <Link href={authorizeUserScopesURL} color={'tertiary.main'}>
            Grant permission
          </Link>{' '}
          to connect to private channels
        </Typography>
      )}
      <Controller
        name='existingChannel'
        control={control}
        render={({ field }) => (
          <Autocomplete
            {...field}
            options={options}
            renderInput={params => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: <InputAdornment position='start'>#</InputAdornment>,
                }}
                variant='outlined'
                fullWidth
                label='Slack Channel'
              />
            )}
            renderOption={(props, option) => (
              <ListItem {...props} key={option.id}>
                <ListItemIcon>{option.isPrivate ? <LockIconRounded /> : <TagIconRounded />}</ListItemIcon>
                <ListItemText primary={option.name} />
              </ListItem>
            )}
            getOptionLabel={option => option.name}
            onChange={(_event, value) => {
              field.onChange({ id: value.id, name: value.name });
            }}
            value={field.value}
          />
        )}
      />
    </>
  );
};

export default ChannelSelect;
