import { useEffect, useState, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PageStyle from '../UI/PageStyle';
import { SectionHeader, SectionHeaderTitle, SectionContent } from '../UI/Section';
import hyveApi from '../util/hyveApi';
import { SlackAuthType } from './useSlackIntegration';

type AuthenticationStatus = 'Success' | 'Failure';

const SlackAuthenticator = () => {
  const [status, setStatus] = useState<AuthenticationStatus | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const stateParam = searchParams.get('state')!;
  const { authType, workspaceId, postAuthRedirect } = JSON.parse(decodeURIComponent(stateParam));
  const authenticationRequested = useRef(false);

  const authenticateWithSlack = async (type: SlackAuthType, code: string, workspaceId: string) => {
    return type === 'user'
      ? hyveApi.create().post('/slack/authenticate_user', { code, workspace_id: workspaceId })
      : hyveApi.create().post('/slack/authenticate_app', { code, workspace_id: workspaceId });
  };

  const handleAuthenticationSuccess = (type: SlackAuthType, result: any) => {
    setStatus('Success');
    if (type === 'user') {
      setMessage('User authentication successful.');
    } else {
      const linkedUserCount = result.data.linked_user_count;
      setMessage(
        linkedUserCount === 0
          ? 'No matching users were found by email, users will be matched by email and linked as they are added to Hyve or Slack.'
          : `${linkedUserCount} users matched by email and linked.`
      );
    }
    setTimeout(() => setRedirect(true), 2000);
  };

  const handleAuthenticationFailure = (error: any) => {
    setLoading(false);
    setStatus('Failure');
    setMessage(error.response?.data?.error || error.message || 'An unknown error occurred');
  };

  useEffect(() => {
    const authenticate = async () => {
      const code = searchParams.get('code');

      if (code && !authenticationRequested.current) {
        authenticationRequested.current = true;
        setLoading(true);

        try {
          const result = await authenticateWithSlack(authType, code, workspaceId);
          setLoading(false);
          handleAuthenticationSuccess(authType, result);
        } catch (error) {
          handleAuthenticationFailure(error);
        }
      } else {
        setStatus('Failure');
        setMessage('Did not receive authorization from Slack');
      }
    };

    authenticate();
  }, [authType, searchParams, workspaceId]);

  useEffect(() => {
    if (redirect) {
      navigate(postAuthRedirect);
    }
  }, [redirect, navigate, postAuthRedirect]);

  return (
    <PageStyle design='simple' includeHeader>
      <SectionHeader>
        <SectionHeaderTitle>Slack Authentication Process</SectionHeaderTitle>
      </SectionHeader>

      <SectionContent>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <>
            {status === 'Success' && (
              <div>
                Success! <strong>{message}</strong> Redirecting...
              </div>
            )}
            {status === 'Failure' && <div>Something went wrong: {message}</div>}
            {!status && <div>Processing...</div>}
          </>
        )}
      </SectionContent>
    </PageStyle>
  );
};

export default SlackAuthenticator;
