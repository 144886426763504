import React from 'react';
import { styled } from '@mui/material/styles';

import CircularProgress from '@mui/material/CircularProgress';

export const LoadingSection = () => (
  <StyledLoading>
    <CircularProgress color='subtle'/>
  </StyledLoading>
);

const StyledLoading = styled('section')`
  width: 100%;
  height: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  .Mui-CircularProgress-root {
    width: 44px;
    height: 44px;
  }
`;

export default LoadingSection;
