import { withErrorHandling } from './error';
import useUser from '../User/useUser';

export const useFullStory = withErrorHandling(() => {
  const { user } = useUser();

  if (user) {
    if (window.FS) {
      window.FS.identify(user.id, {
        displayName: `${user.firstName} ${user.lastName}`,
        email: user.email,
        // TODO: Add your own custom user variables here, details at
        // https://help.fullstory.com/hc/en-us/articles/360020623294-FS-setUserVars-Recording-custom-user-data
      });
    }
  }
});
