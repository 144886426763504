import React from 'react';
import { styled } from '@mui/material/styles';
import classnames from 'classnames';

interface ProgressBarProps {
  stepIndex: number | undefined;
  stepCount: number | undefined;
}

const ProgressBar = ({ stepIndex, stepCount }: ProgressBarProps) => {
  if (stepIndex === undefined) {
    return null;
  }
  const steps = [...Array(stepCount)];
  return (
    <StyledProgressBar>
      {steps.map((step, index) => (
        <div
          key={index}
          className={classnames({
            'progress-step': true,
            active: index === stepIndex,
            completed: index < stepIndex,
          })}
        />
      ))}
    </StyledProgressBar>
  );
};

export default ProgressBar;

const StyledProgressBar = styled('div')`
  width: 100%;
  height: 5px;
  background-color: #666;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: row;

  .progress-step {
    flex-grow: 1;
    border-left: 2px solid #1a1a1a;
    &:first-of-type {
      border-left: 0;
    }
    &.active,
    &.completed {
      background-color: ${props => props.theme.palette.secondary.main};
    }
  }
`;
