import { Outlet } from 'react-router-dom';
import { useWorkspace, WorkspaceProvider } from './useWorkspace';
import { ScopeProvider } from '../Scope/ScopeContext';
import WorkspaceNavigation from '../Navigation/WorkspaceNavigation';
import { IntelligenceProvider } from '../Intelligence/IntelligenceContext';
import { AIProvider } from '../AI/AIContext';

const Workspace = () => {
  return (
    <WorkspaceProvider>
      <WorkspaceContent />
    </WorkspaceProvider>
  );
};

const WorkspaceContent = () => {
  const { workspace } = useWorkspace();
  return (
    <AIProvider>
      <IntelligenceProvider>
        <ScopeProvider scorable={workspace}>
          <WorkspaceNavigation>
            <Outlet context={{ scorable: workspace }} />
          </WorkspaceNavigation>
        </ScopeProvider>
      </IntelligenceProvider>
    </AIProvider>
  );
};

export default Workspace;
