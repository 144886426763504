import { useState, useContext, useEffect, useRef } from 'react';
import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client';
import { createConsumer } from '@rails/actioncable';
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';

import { TOKEN } from '../User/User';
import AuthenticationContext from '../User/AuthenticationContext';
import { typePolicies, possibleTypes } from './apolloTypePolicies';

// TODO add token refresh
// https://medium.com/@lucasmcgartland/refreshing-token-based-authentication-with-apollo-client-2-0-7d45c20dc703
// NOTE: If the Apollo Client Devtools extension isn't showing data, sometimes restarting the browser fixes the issue [rolls eyes].

export const useApolloClient = () => {
  const { deauthenticate } = useContext(AuthenticationContext);
  const clientInitialized = useRef(false);
  const [client, setClient] = useState(null);

  // Only create the client once to avoid problems with the Apollo Chrome extension
  useEffect(() => {
    if (!clientInitialized.current) {
      const newClient = createClient({ deauthenticate });
      setClient(newClient);
      clientInitialized.current = true;
    }

    return () => {
      if (client) {
        client.stop();
        client.resetStore();
        clientInitialized.current = false;
      }
    };
  }, [deauthenticate]);

  return client;
};

const createClient = ({ deauthenticate }) => {
  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_SERVER}/graphql`,
    credentials: 'include',
  });

  const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem(TOKEN);
    //    if (!token) {
    //      throw new Error('No Authentication Token');
    //    }
    // Return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const errorLink = onError(({ response, graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path, extensions }) => {
        if (extensions?.code === 'AUTHENTICATION_ERROR') {
          response.errors = null;
          deauthenticate();
        } else if (extensions?.code === 'AUTHORIZATION_ERROR') {
          console.error(message);
        } else {
          console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
        }
      });
    }
    if (networkError) {
      console.error(`[Network error]: ${networkError}`);
    }
  });

  const afterwareLink = new ApolloLink((operation, forward) => {
    return forward(operation).map(response => {
      const headers = operation.getContext().response.headers;
      if (headers) {
        const authorizationHeader = headers.get('Authorization');
        if (authorizationHeader) {
          const token = authorizationHeader.replace('Bearer ', '');
          localStorage.setItem(TOKEN, token);
        }
      }

      return response;
    });
  });

  const retryLink = new RetryLink({
    attempts: {
      retryIf: (error, _operation) => {
        const nonRetryStatusCodes = [400, 401, 403, 404, 409, 422, 500];
        return !nonRetryStatusCodes.includes(error.statusCode);
      },
    },
  });

  const fullHttpLink = ApolloLink.from([retryLink, errorLink, afterwareLink, authLink, httpLink]);

  // Create the web sockets link
  const hasSubscriptionOperation = ({ query: { definitions } }) => {
    return definitions.some(({ kind, operation }) => kind === 'OperationDefinition' && operation === 'subscription');
  };

  const cable = createConsumer(`${process.env.REACT_APP_SERVER}/cable`);

  const splitLink = ApolloLink.split(hasSubscriptionOperation, new ActionCableLink({ cable }), fullHttpLink);

  return new ApolloClient({
    connectToDevTools: true,
    cache: new InMemoryCache({
      typePolicies: typePolicies,
      possibleTypes: possibleTypes,
    }),
    link: splitLink,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all',
      },
      query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      },
      mutate: {
        errorPolicy: 'all',
      },
    },
  });
};
