import React from 'react';
import { styled } from '@mui/material/styles';
import { Stack } from '@mui/material';

const SurveyMessage = ({ children }: { children: React.ReactNode }) => {
  return <StyledMessage spacing={2}>{children}</StyledMessage>;
};

const StyledMessage = styled(Stack)`
  z-index: 1;

  .surveys {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  .collect-intelligence {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    button {
      cursor: pointer;
      color: #0091ff;
      &:hover {
        color: inherit;
      }
      &:active {
        color: #000;
      }
    }
  }
`;

export default SurveyMessage;
