import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import { NavLink, UIMatch, resolvePath, useMatches } from 'react-router-dom';
import { Box, Tab, Tabs } from '@mui/material';
import { useWorkspace } from '../Workspace/useWorkspace';
import { Role } from '../User/Roles';

type ExtendedUIMatch = UIMatch & { handle?: { secondaryArea?: string } };

export const useSecondaryNavigationTabs = (tabs: SecondaryNavigationTab[], basePath: string) => {
  const matches = useMatches() as ExtendedUIMatch[];

  const secondaryArea = matches.find(match => match.handle?.secondaryArea)?.handle?.secondaryArea;
  const matchingTab = tabs.find(tab => tab.key === secondaryArea) ?? tabs[0];
  const [selectedTab, setSelectedTab] = useState<SecondaryNavigationTab>(matchingTab);

  useEffect(() => {
    if (matchingTab.key !== selectedTab.key) {
      setSelectedTab(matchingTab);
    }
  }, [matchingTab, selectedTab]);

  return { tabs, selectedTab, setSelectedTab, basePath };
};

interface SecondaryNavigationTabsProps {
  tabs: SecondaryNavigationTab[];
  selectedTab: SecondaryNavigationTab;
  setSelectedTab: (selectedTab: SecondaryNavigationTab) => void;
  onChange?: (tab: SecondaryNavigationTab) => void;
  basePath: string;
}

export const SecondaryNavigationTabs = ({ tabs, selectedTab, setSelectedTab, basePath }: SecondaryNavigationTabsProps) => {
  const { workspace } = useWorkspace();

  // Filter out sections for which the user does not have access
  const filteredTabs = tabs.filter(tab => (tab.roles ? tab.roles.some(role => workspace.effectiveRoles.includes(role)) : true));

  const selectedTabIndex = tabs.findIndex(tab => tab.key === selectedTab.key);

  const handleChange = (_: React.SyntheticEvent, index: number) => {
    const selectedTab = tabs[index];
    setSelectedTab(selectedTab);
  };

  return (
    <StyledTabs>
      <Tabs
        value={selectedTabIndex}
        onChange={handleChange}
        aria-label='client navigation tabs'
        variant='scrollable'
        scrollButtons='auto'
        allowScrollButtonsMobile={false}
      >
        {filteredTabs.map((tab, index) => (
          <Tab key={tab.key} value={index} label={tab.label} to={resolvePath(tab.path, basePath).pathname} component={NavLink} />
        ))}
      </Tabs>
    </StyledTabs>
  );
};

export interface SecondaryNavigationTab {
  key: string;
  label: string;
  path: string;
  roles?: Role[];
}

const StyledTabs = styled(Box)(
  ({ theme }) => `
  max-width: 100%;
  .tablet &, .laptop &, .desktop & {
    .MuiTab-root {
      min-width: 100px;
    }
  }
  .mobile & {
    .MuiTab-root {
      min-width: auto;
    }
  }

  .MuiTabs-root {
    overflow: visible; /* for indicator */
    .MuiTab-root {
      text-transform: inherit;
      /* height: var(--header-height); */

      &.Mui-selected {
        color: #ffd700;
        opacity: 1.0;
      }
    }
    .MuiTabs-scroller {
      /* overflow: visible !important; */ /* for indicator */
    }
    .MuiTabs-indicator {
      height: 4px;
      bottom: -1px;
      background-color: ${theme.palette.secondary.main};
    }
  }
`
);
