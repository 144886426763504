import React, { useState } from 'react';
import { Button, IconButton, Popover, Stack, Typography } from '@mui/material';
import { DateCalendar } from '@mui/x-date-pickers';
import EventRoundedIcon from '@mui/icons-material/EventRounded';

import { FriendlyDate } from '../util/dateFormat';
import { ProjectInterface, UPDATE_PROJECT_MUTATION } from './ProjectInterface';
import { DateTime } from 'luxon';
import { useMutation } from '@apollo/client';
import { handleMutationResponse } from '../util/handleMutationResponse';
import { useGlobalMessage } from '../UI/GlobalMessage';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

type ProductDueDateProps = {
  project: ProjectInterface;
};

const ProjectDueDate = ({ project }: ProductDueDateProps) => {
  const { endDate } = project;
  const initialDate = endDate ? DateTime.fromISO(endDate) : null;

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedDate, setSelectedDate] = useState<DateTime | null>(initialDate); // Initialize selectedDate with endDate

  const [updateProject] = useMutation(UPDATE_PROJECT_MUTATION);
  const { setSuccess, setError } = useGlobalMessage();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleDateChange = async (date: DateTime | null) => {
    const { id, name, client } = project;

    const response = await updateProject({ variables: { id, name, client: { id: client.id }, endDate: date } });
    const { success, errorMessage } = handleMutationResponse(response, 'updateProject', 'Uh oh. There was an error updating the project.');

    if (success) {
      setSelectedDate(date);
      setSuccess(`Updated project`);
      //          if (refetch) {
      //            await refetch();
      //          }
    } else {
      setError(errorMessage);
    }

    handleClose();
  };

  const handleClearDate = async () => {
    await handleDateChange(null);
  };

  const id = open ? 'date-popover' : undefined;

  return (
    <>
      {selectedDate ? (
        <Stack direction='row'>
          <Button onClick={handleClick} sx={{ minWidth: 0, fontSize: 'inherit' }}>
            <FriendlyDate fontSize='inherit' dateInput={selectedDate ? selectedDate.toJSDate() : null} />
          </Button>
          {/*<IconButton size='small' onClick={handleClearDate}>*/}
          {/*  <CloseRoundedIcon fontSize='inherit' />*/}
          {/*</IconButton>*/}
        </Stack>
      ) : (
        <Button color='neutral' onClick={handleClick} sx={{ minWidth: 0, fontSize: 'inherit' }}>
          Not set
        </Button>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Stack alignContent='center'>
          <DateCalendar value={selectedDate} onChange={handleDateChange} />
          {selectedDate && (
            <Button variant='text' onClick={handleClearDate}>
              Clear Date
            </Button>
          )}
        </Stack>
      </Popover>
    </>
  );
};

export default ProjectDueDate;
