import { styled, ThemeProvider } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';

import { ReactComponent as HeaderLogo } from '../Icons/Hyve-Logo.svg';

import { HYVE_YELLOW } from '../Theme/AppTheme';

import { DRAWER_WIDTH } from './PrimaryDrawerNavigation';
import { NAVIGATION_THEME } from '../UI/NavigationTheme';
import WorkspaceActions from '../Workspace/WorkspaceActions';
import { useWorkspace } from '../Workspace/useWorkspace';

const PrimaryTopNavigation = () => {
  const { workspace } = useWorkspace();

  return (
    <ThemeProvider theme={NAVIGATION_THEME}>
      <StyledAppBar position='sticky'>
        <Toolbar disableGutters>
          <Stack height='100%' flexGrow='1' direction='row' justifyContent='space-between'>
            <Link className='logo' to={`/workspace/${workspace.id}`}>
              <Stack width={DRAWER_WIDTH} pl={2.5} direction='row' alignItems='center' spacing='12px'>
                <HeaderLogo />
                <Box color='#666' border='1px solid #666' padding='0px 6px' borderRadius='10px' fontSize='10px'>
                  BETA
                </Box>
              </Stack>
            </Link>
            <Stack direction='row' alignItems='center'>
              <Divider orientation='vertical' />
              <WorkspaceActions />
            </Stack>
          </Stack>
        </Toolbar>
      </StyledAppBar>
    </ThemeProvider>
  );
};

export default PrimaryTopNavigation;

const StyledAppBar = styled(AppBar)(
  ({ theme }) => `
  z-index: ${theme.zIndex.drawer + 1};
  background-color: ${theme.palette.common.black};
  background-image: none;
  box-shadow: none;

  .MuiToolbar-root {
    .logo {
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .MuiButtonBase-root {
      // margin: 10px;
      // padding: 12px 12px;
      .MuiIcon-root {
        font-size: 28px;
      }
    }

    .MuiTabs-root {
      .MuiTabs-indicator {
        display: none;
      }
      .MuiTab-root {
        &:hover {
          background-color: rgba(255, 255, 255, 0.08);
        }
        color: #fff;
        .navigation-icon {
          fill: #fff;
          g {
            fill: #fff;
          }
        }
        &.Mui-selected {
          color: ${HYVE_YELLOW};
        }
      }
    }
  }
`
);
