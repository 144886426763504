import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Box, Divider, Stack } from '@mui/material';
import HexagonRoundedIcon from '@mui/icons-material/HexagonRounded';

import IntelligenceNotice from '../Intelligence/IntelligenceNotice';
import PageStyle from '../UI/PageStyle';
import DashboardSummary from '../Dashboard/DashboardSummary';
import { DashboardSurface } from '../Dashboard/Dashboard';
import { PersonalDashboardStats } from '../Dashboard/DashboardStats';
import DashboardAspects from '../Dashboard/DashboardAspects';
import { CATEGORY_SCORE_FIELDS_FRAGMENT } from '../Category/Category';
import { SCORABLE_SCORE_FIELDS_FRAGMENT } from '../Scores/ScorableBase';
import { useTimeframe } from '../User/useTimeframe';
import { useProjectContext } from './ProjectContext';
import ProjectImportanceRating from './ProjectImportanceRating';
import ProjectStatusSelect from './ProjectStatusSelect';
import ProjectDueDate from './ProjectDueDate';
import { getModule } from '../Module/useModule';
import { useLayout } from '../UI/LayoutContext';

const ProjectDashboard = () => {
  const { project } = useProjectContext();
  const { client } = project;
  const { days } = useTimeframe();
  const { data } = useQuery(PROJECT_SCORES_QUERY, { variables: { id: project.id, days } });

  if (!data) {
    return null;
  }

  const projectData = data.project;
  const categoryData = projectData.categories[0];
  const moduleData = getModule(projectData, 'ClientsProjectsModule') || getModule(projectData, 'CompanyProjectsModule');

  if (!moduleData) {
    return null;
  } // The cache may not have this module loaded.

  return (
    <div className='dashboard'>
      <IntelligenceNotice scorable={project} />
      <PageStyle design='standard' fullWidth theme='dark'>
        <DashboardSurface variant='primary' title={`${client.name} - ${project.name}`} subtitle={<ProjectDashboardSubtitle />}>
          <DashboardSummary scorable={data?.project} scorableLabel={'Project'} icon={HexagonRoundedIcon} />
          <PersonalDashboardStats scorable={projectData} intelligenceLabel={'Project Intelligence'} />
        </DashboardSurface>

        <DashboardAspects scorable={projectData} category={categoryData} module={moduleData} />
      </PageStyle>
    </div>
  );
};

const ProjectDashboardSubtitle = () => {
  const { project } = useProjectContext();
  const { endDate } = project;
  const layout = useLayout();

  const status = (
    <Stack direction='row' alignItems='center' spacing={0.5}>
      <Box>Status:</Box>
      <ProjectStatusSelect project={project} size='small' sx={{ fontSize: 'inherit' }} />
    </Stack>
  );

  const dueDate = (
    <Stack direction='row' alignItems='center' spacing={0.5}>
      <Box>Due:</Box>
      <ProjectDueDate project={project} />
    </Stack>
  );

  const importance = (
    <Stack direction='row' alignItems='center' spacing={0.5}>
      <Box>Importance:</Box>
      <ProjectImportanceRating project={project} size='small' />
    </Stack>
  );

  if (layout === 'mobile') {
    return (
      <Stack spacing={1} alignItems='center'>
        <Stack direction='row' spacing={2}>
          {status}
          {dueDate}
        </Stack>
        {importance}
      </Stack>
    );
  } else {
    return (
      <Stack direction='row' spacing={2} divider={<Divider orientation='vertical' flexItem />}>
        {status}
        {dueDate}
        {importance}
      </Stack>
    );
  }
};

const PROJECT_SCORES_QUERY = gql`
  query ProjectScores($id: ID!, $days: Int) {
    project(id: $id) {
      id
      name
      ...ScoreFields
      memberStats {
        count
      }

      categories {
        id
        name
        type
        ...CategoryScoreFields
      }
    }
  }

  ${SCORABLE_SCORE_FIELDS_FRAGMENT}
  ${CATEGORY_SCORE_FIELDS_FRAGMENT}
`;

export default ProjectDashboard;
