import React, { createContext, useState, useEffect } from 'react';

import hyveApi from '../util/hyveApi';

import { WORKSPACE_ID_KEY } from '../Workspace/WorkspaceInterface';
import { TOKEN, UserInterface } from './User';

interface AuthenticationContextValue {
  signIn: (email: string, password: string) => Promise<UserInterface>;
  authenticate: () => void;
  deauthenticate: () => void;
  authenticated: boolean;
  token: string | null;
  userId: string | null;
}

const AuthenticationContext = createContext<AuthenticationContextValue | undefined>(undefined);

interface AuthenticationProviderProps {
  children: React.ReactNode;
}

export const AuthenticationProvider = ({ children }: AuthenticationProviderProps) => {
  const [token, setToken] = useState(localStorage.getItem(TOKEN));
  const [authenticated, setAuthenticated] = useState(!!token);

  let userId;
  if (token) {
    const payload = token.split('.')[1];
    const base64 = payload.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    const json = JSON.parse(jsonPayload);
    userId = json.sub;
  }

  useEffect(() => {
    const deauthenticateForTesting = () => {
      console.log('DEAUTH FOR TESTING');
      deauthenticate();
    };

    window.addEventListener('deauthenticate-for-testing', deauthenticateForTesting);
    return () => {
      window.removeEventListener('deauthenticate-for-testing', deauthenticateForTesting);
    };
  }, []);

  const authenticate = () => {
    setToken(localStorage.getItem(TOKEN));
    setAuthenticated(true);
  };

  const deauthenticate = async () => {
    try {
      await hyveApi.create().delete('/users/sign_out');
    } catch (error) {
      console.error('Error logging out:', error);
    } finally {
      localStorage.removeItem(TOKEN);
      localStorage.removeItem(WORKSPACE_ID_KEY);
      setAuthenticated(false);
    }
  };

  const signIn = async (email: string, password: string): Promise<UserInterface> => {
    const response = await hyveApi.create({ noAuth: true }).post('/users/sign_in', {
      user: { email: email, password: password },
    });

    const authorizationHeader = response.headers.authorization!;
    const token = authorizationHeader.split(' ')[1];

    const user = response.data;

    localStorage.setItem(TOKEN, token);
    authenticate();

    return user;
  };

  const value = {
    signIn,
    authenticate,
    deauthenticate,
    authenticated,
    token,
    userId,
  };

  return <AuthenticationContext.Provider value={value}>{children}</AuthenticationContext.Provider>;
};

export default AuthenticationContext;
